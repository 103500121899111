$grey-bg: rgba(238, 238, 238, 0.8);
$white-bg: #fff;
$dark-grey: #9B9B9B;
$dark2-grey: #636363;
$dark3-grey: #323232;
$heading-grey: #4E4E4E;
$subheading-grey :  rgba(78, 78, 78, 0.5);

$dropdown-arrow-grey: #7B7B7B;
$dropdown-txt-grey: #4E4E4E;
$light2-grey: #7D7D7D;
$light-grey: #f0f0f0;
$btn-bg:#EEEEEE;
$black-txt:#000;
$green:#56b30b;
$flur-green:#44F055;
$red:#ed4741;
$hover-grey: #cdcdcd;
$hover-blue:#007bff;
$chart-blue:#71B2FF;
$green-gradient-btn : linear-gradient(to right, #225D60 0%, #6AB2A0 50%,#225D60 100%);
$green-gradient-icn : linear-gradient(to right, #225D60 0%, #6AB2A0 60%);
