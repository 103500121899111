.daz-login{
    height: 100vh;
    min-height: 700px;
    width: 95%;
    margin: 0 auto ;

    &_logo{
        height: 100px;
        img{
            width: 170px;
        }
    }

    &_inner{
        height: calc(100% - 140px);
        
        padding: 7%;
        margin-bottom: 50px;
    }    
    &_icon{
        transform: rotate(270deg);
    }
}


