.daz-admin-wrp{
    display: flex;
    flex-direction: column;
    height: 100vh;

    .daz-main-wrp{
        flex-grow: 1;
    }

    .daz-main-content{
        width: 100%;
    }
    // .modal-dialog .form-group .form-control input::-webkit-calendar-picker-indicator, input[type="date"]::-webkit-calendar-picker-indicator {
    //     cursor: pointer;
    //     border-radius: 4px;
    //     margin-right: 2px;
    //     opacity: 0.9 !important;
    //     filter: invert(0.5)!important;
    //   }
      
    // input[type="date"]::-webkit-calendar-picker-indicator:hover {
    // opacity: 1
    // }
      
}