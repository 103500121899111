.daz-header{
    padding: 10px;
    display: flex;
    justify-content: space-between;

    &__profilewrap{
        i{
            font-size: 43px;
            display: block;
            color: $dark2-grey;
        }
        span{
            color: $light2-grey;
        }
    }

    &__dropdown{
        .dropdown-menu{
            min-width: 7rem;
        }

        button{
            background-color: transparent !important;
            color: inherit !important;
            border: none  !important;
            outline: none !important;
            box-shadow: none !important;
        
           
            &:after{
                display: none;
            }
        }
    } 
    
  

    &__logout{
        width: 100%;
    }

    &__logo{
        img{
            width: 115px;
        }
    }

}

.summary-graph{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__each{
        width: 190px;
        border:solid 10px $grey-bg;
        margin: 0 10px;
        text-align: center;
        border-radius: 5px;
        padding-top: 10px;
        position: relative;

    }

    &__expand{
        position: absolute;
        right: 0;
        top: 0;
        font-size: 14px;
    }

    .downward-arrow{
        color: $green;
        font-size: 20px;
    }
    .upward-arrow{
        color: $red;
        font-size: 20px;
    }
    h5{
        color:$black-txt;
        font-size: 16px;
        font-weight: normal;
    }
    .perc-arrow{
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
    .percentage-value{
        position: relative;
        bottom: -8px;
        z-index: 1;
    }
    i {
        color: #9B9B9B;
        cursor: pointer;
    }
}