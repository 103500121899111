.daz-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
  
    // Box.
    &.left + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      background:$dark2-grey;
    }
      &.left + label:after{
        content: '';
        position: absolute;
        left: 4px;
        top: 12px;
        background: $white-bg;
        width: 2px;
        height: 2px;
        box-shadow: 
          2px 0 0  $white-bg,
          4px 0 0  $white-bg,
          4px -2px 0  $white-bg,
          4px -4px 0  $white-bg,
          4px -6px 0  $white-bg,
          4px -8px 0  $white-bg;
        transform: rotate(45deg);
    }
  
    &.right + label:after {
        content: '';
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        background:$dark2-grey;
        
      }
      &.right:disabled + label:after{
        background:$dark-grey;

      }
      &.right + label:before,&.right:disabled + label:before{
      content: '';

        position: absolute;
        right: 12px;
        top: 12px;
        background: $white-bg;
        width: 2px;
        height: 2px;
        box-shadow: 
          2px 0 0  $white-bg,
          4px 0 0  $white-bg,
          4px -2px 0  $white-bg,
          4px -4px 0  $white-bg,
          4px -6px 0  $white-bg,
          4px -8px 0  $white-bg;
        transform: rotate(45deg);
      }

    // Box hover
    // &:hover + label:before {
    //   background: #f35429;
    // }
    
    // Box focus
    // &:focus + label:before {
    //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    // }
  
    // Box checked
    // &:checked + label:before {
    //   background: #f35429;
    // }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  
    // Checkmark. Could be replaced with an image
    &.left:checked + label:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 12px;
      background: $flur-green;
        width: 2px;
        height: 2px;
        box-shadow: 
          2px 0 0  $flur-green,
          4px 0 0  $flur-green,
          4px -2px 0  $flur-green,
          4px -4px 0  $flur-green,
          4px -6px 0  $flur-green,
          4px -8px 0  $flur-green;
        transform: rotate(45deg);
    }
    &.right:checked + label:before,&.right:checked:disabled + label:before {
        content: '';
        position: absolute;
        right: 12px;
        top: 12px;
        background: $flur-green;
        width: 2px;
        height: 2px;
        box-shadow: 
          2px 0 0  $flur-green,
          4px 0 0  $flur-green,
          4px -2px 0  $flur-green,
          4px -4px 0  $flur-green,
          4px -6px 0  $flur-green,
          4px -8px 0  $flur-green;
        transform: rotate(45deg);
      }
  }
  