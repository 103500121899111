.daz-table-striped-wrp{
    background-color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 7px;
}
.daz-table-striped{
    border-collapse: separate; 
    border-spacing: 0;
    
    th{
        font-size: 15px;
        color: #000;
        padding-left: 15px;
        padding-right: 15px;
    }    
    td{
        color: #7B7B7B;
        box-shadow: none;
        padding-left: 15px;
        padding-right: 15px;

        i{
            color: #636363;
        }
    }
    tr:nth-of-type(odd) td{
        background-color: rgba(246, 246, 246, 1);
    }
    td:not(:last-child),th:not(:last-child){

        border-right:  solid 1px #EEE ;
    }
}