.daz-main-wrp{
    display: flex;
}
.daz-main-content{
    // flex-grow: 1;
    background-color: $grey-bg;
    margin:0 10px 10px;
    padding: 20px 30px 0;
    width: calc(100vw - 135px);
    position: relative;
    .four-layout{
        .each-col{
            position: relative;
        }
    }
}

.home-chart-layout{
    background-color: $white-bg;
    padding: 10px;
    margin-bottom: 20px;
    .top-functions{
        height: 29px;
    }

    i{
        color: $dark-grey;
        cursor: pointer;
    }
    .custom-legend{
        position: absolute;
        right: 25px;
        bottom: 25px;
        font-size: $font-xsmall;
        color:rgba(99, 99, 99, 0.8);

        &:before{
            content: "";
            width: 10px;
            height: 2pz;
            border-top:  2px dotted $chart-blue;
            margin-right: 5px;
        }
    }

    .daz-icon__dropdown > button{
        padding: 10px 5px 0;
    }
}

.highcharts-wrp{
    height: 260px;
}
.chart-layout-expanded .home-page .highcharts-wrp{
    height: 580px;
}
.chart-layout-expanded .highcharts-wrp,.single-chart-page .highcharts-wrp{
    height: 520px;
}
.process-page .highcharts-wrp{
    height: 490px;
}
// .four-layout{
    // height: 500px;
    // overflow: hidden;
// }

.each-col{
    height: 50%;
}
.chart-layout-expanded > div{
    height: 100%;
}


.graph-filter-btn{
    background-color: $grey-bg;
    margin-right: 12px;
    padding: 3px 7px;
    border: none;
    font-size: 14px;
    border-radius: 3px;   
    color: rgb(99, 99, 99);

    &.graph-filter-active{
        border: 1px solid #7D7D7D;

    }
  
}

.process-nav,.process-nav-dropdown{
    display: flex;
    align-items: center;
    justify-content: center;
    color:$dark2-grey;

    i{
        width: 24px;
        margin: 0 10px;
        cursor: pointer;
        margin-top: 4px;
    }
    h4{
        color: $dropdown-txt-grey;
        font-weight: $font-light;
    }
}
.process-nav-dropdown{
    position: relative;
    margin-bottom: 20px;
    
    .dropdown{
        right: 0;
    }
    a{
        color: $black-txt;
        display: block;
    }

    .bt-dropdown-menu{
        max-height: 350px;
        overflow-y: auto;

    }
}
.inner-breadcrumb{
    font-weight: $font-light;

    i{
        width: 24px;
        margin-right: 10px;
        color: $dropdown-arrow-grey;
    }
    a{
        display: inline-flex;
        align-items: center;
        color: $dark3-grey;

    }
}

.chart-bg{
    background-color: #fff;
    padding: 10px;

    i{
        color: $dark-grey;

    }
    .top-functions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .daz-icon__dropdown > button {
            padding: 10px 5px 0;
        }
        i{
            cursor: pointer;
        }
    }
}

.white-chart-bg{
    background-color: #fff;
    width: 100%;
    height: 100%;
}
.equipment-filter{
    background: #FFFFFF;
    border: 1px solid #9B9B9B;
    border-radius: 3px;
    color: #636363; 
    cursor: pointer;
    font-weight: 400;
    padding: 0px 8px;
    font-size: 12px;
}
.equipment-filter.active{
    background: #7D7D7D;
    border: 1px solid #7D7D7D;
    color: #fff;
}
.layout-heading{
    color: $dropdown-txt-grey;
    font-weight: $font-light;
    margin-bottom: 20px;
}