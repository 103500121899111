.daz-sidebar{
    background-color:$grey-bg;
    max-width: 125px;
    text-align: center;
    position: relative;
    
    &__iconwrap{
        padding: 10px;
        display: block;
        text-decoration: none;
        color:$dark2-grey;
        position: relative;
        
        span{
            color: #7d7d7d;
            font-weight: $font-light;
            font-size: 15px;
        }
        &:hover,&--active{
            cursor: pointer;
            text-decoration: none;
            color:$dark2-grey;

            i {
            color:#9cd094;
                background:$green-gradient-icn;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }    
        }

        &--disabled{
            pointer-events: none;
            color: $dark-grey;
        }

        i{
            display: block;
            font-size: 40px;          
        }
    }

    &__hoverwrp:hover{
        .daz-sidebar__hoverinfo{
            //display: block;
            left: 100%;
            transition: left 0.1s ease;

        }
    }

    &__dropdown-hov{
        display: flex!important;
        align-items: center;
        justify-content: space-between;
        line-height: 1.1;

        &::after{
        content: "arrow_drop_down";
        font-family: "Material Icons";
        font-size: 30px;
        color: $dark2-grey;
        }

    }
    .daz-sidebar__dropdown-wrp:hover{
            
        > .daz-sidebar__dropdown-hov::after{
            content: "arrow_drop_up";
            color: rgba(123, 123, 123, 0.5);
        }
    }
    &__dropdown-click{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        line-height: normal;

        &::after{
            content: "arrow_drop_down";
            font-family: "Material Icons";
            font-size: 30px;
            color: $dark2-grey;
        }
        &.active::after{
            content: "arrow_drop_up";
            color: rgba(123, 123, 123, 0.5);
        }
    }

    &__dropdown{
        display: none;
        // margin-left: 10px;
        
        &--item{
            display: flex;
            align-items: center;
        }
        &--disabled{
            pointer-events: none;
            opacity: 0.5;
            cursor: default !important;
        }
    }
    &__dropdown-wrp{
        > .daz-sidebar__hoverinfo--item > a{
          font-weight: $font-bold; 
          color: $dropdown-txt-grey; 
          white-space: nowrap;
        }
    }
    &__dropdown-wrp:hover{
        >.daz-sidebar__dropdown{
             display: block;
        }
    }
    
    .subprocess-dropdown{
        border: solid 1px $dropdown-arrow-grey;
    }

    &__dropdown-click{
        cursor: pointer;
        padding-left: 13px;
        width: 100%;
    }
    &__clickdropdown{
        display: none;
    }
    &__dropdown-click.active + .daz-sidebar__clickdropdown{
            display: block;
    }

    &__hoverinfo{
        
        z-index: 3;
        position: absolute;
        background: #fff;
        top: 10px;
        left: -99999px;
        padding: 10px;
        min-width: 170px;
        max-height: 290px;
        overflow-y: auto;
        text-align: left;
        &--item{
            display: block;
        }

        &--active{
            background-color: $grey-bg;
        }
        a,&--click{
            cursor: pointer;
            text-decoration: none;
            &:hover{
                text-decoration: none;

            }
            font-weight: $font-light;
            color: $dropdown-txt-grey;
            padding: 5px 13px;
            width: 100%;
        }
      
    }

    &__processListing{
        transition: left 0.1s ease 0.8s;
    }
    
}


