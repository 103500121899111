.daz-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;

  input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #7B7B7B;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0px;
      bottom: 0px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      background-color: #44F055;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #44F055;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }  
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
    input:disabled + .slider {
      cursor: initial;
    }
}
  .form-check-input:checked {
    background-color: #44F055; /* Bootstrap's success color */
    border-color: #44F055; /* Border color when checked */
  }
  
  .form-check-input:checked:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); /* Optional: shadow to match success color */
  }
  
  .form-check-input:before {
    background-color: #198754; /* Slider color */
  }
  
  .form-check-input:checked:before {
    transform: translateX(1.5rem); /* Move slider when checked */
  }
