.daz-icon__dropdown{
    .dropdown-menu{
        min-width: 7rem;
        background: #F8F8F8;
        border:none;
        border-radius: 5px;
    }
    .dropdown-item{
        padding-left: 0;
        padding-right: 0;
    }
    button{
        background-color: transparent !important;
        color: inherit !important;
        border: none  !important;
        outline: none !important;
        box-shadow: none !important;
        padding: .25rem 1.5rem;
       
        &:after{
            display: none;
        }
    }

    &-settings{
        position: relative;
        font-size: 23px;
        top:-2px;
    }

} 

.daz-grey__dropdown{
    .dropdown-toggle,.dropdown-toggle:focus,.dropdown-toggle:active{
        background-color: #EEEEEE !important;
        border:none;
        border-radius: 5px;
        border: none  !important;
        outline: none !important;
        color: #000 !important;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25) !important;
    }
    .dropdown-toggle:focus,.dropdown-toggle:active{
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .dropdown-menu{
        max-height: 207px;
        overflow: auto;
    }
    .dropdown-item.active{
        background-color:$light-grey;
        color: #000;
    }
    .dropdown-item:hover{
        background-color:$light-grey;
    }
}