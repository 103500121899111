.daz-note{
    background: #EDEDED;
    font-size: $font-xsmall;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px 10px 12px;
    text-align: left;
    h3 {
        color: #696969;
    font-size: $font-xsmall;

    }
    p{
        color: #000;
    }
    .blue{
        color: $hover-blue;
    }
}   