.daz-clientreg-page{

    .daz-table-striped-wrp{
        max-width: max-content;
    }
    
}
.companyInput{
    // margin: 10px;
    padding: 10px;
}
.companyInputBlock{
    // max-width: 200px;
    // margin: 10px;
    padding: 5px;
    
}
.smallInput{
    width: 120px;
    margin-right: 4px;
    color: #7b7b7b;

    input{
        max-width: 115px !important;
        color: #7b7b7b;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;

        // box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
        // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;

    }
    // background-color:green;
}
.mediumInput{
    width: 190px;
    margin-right: 4px;
    color: #7b7b7b;

    input{
        max-width: 160px !important;
        color: #7b7b7b;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;

        // box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
        // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

    }
}
.largeInput{
    width: 250px;
    color: #7b7b7b;

    input{
        color: #7b7b7b;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;

        // box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
        // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        max-width: 240px !important;

    }
    // background-color: red;
}
.clientInput{
    max-height: 35px;
}
.tableHeader{
    font-size: 15px;
    color: #000;
    padding-left: 0px;
    padding-right: 15px;
    margin-bottom: 5px;
    font-weight: 700;
    padding: 5px;
    margin-right: 5px;
    
}
.editBlock{
    width: 20px;
}
.hideCell{
    width: 0px !important;
    padding-left: 0px;
}
.daz-client-block-striped-wrp{
    // background-color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 7px;
}
.daz-client-reg-sub-block{
    padding-top: 15px;
    padding-bottom: 15px;
    overflow-x: auto;
}
.daz-row-class{
    // display: inline-flex;
    // overflow-x: scroll;
    // width: 1250px !important;
    // overflow: hidden;
    width: max-content;
}
.daz-gray-bg{
    background-color: $light-grey !important;
}
.bg-white{
    background-color: #fff !important;
}
.switch-list-label{
    min-width: 120px;
}
.modal-label{
    font-weight: 500;
}
.plant-list-labol{

}
.custom-dropdown-menu {
    max-width: 200px; /* Set a max width to the dropdown */
    overflow: hidden; /* Hide overflowing content */
  }
  
  .custom-dropdown-menu .dropdown-item {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis for truncated text */
  }
